<template>
  <div>
    <div v-if="!link.addTag" class="space-y-1">
      <ul class="flex justify-center items-center gap-x-1">
        <template v-for="(tag,tag_index) in link.tags"
                v-if="getTagName(tag) != '' && getTagName(tag) && getTagName(tag).length > 0">
        <li v-if="tag_index < 3 " class="flex gap-x-1 bg-[#F2F3F8] border border-[#ECEEF5] justify-between items-center bg-[#F2F3F8] text-sm font-poppins px-[0.25rem] rounded-lg max-width-100">
          <span class="text-truncate-tag">{{ getTagName(tag) }}</span>
          <span @click.prevent="removeLinkTag(link._id,index,tag,false,tag_index)">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79881 2.26275C9.0917 1.96986 9.0917 1.49499 8.79881 1.20209C8.50592 0.909199 8.03104 0.909199 7.73815 1.20209L5.00045 3.93979L2.26275 1.20209C1.96986 0.909199 1.49498 0.909199 1.20209 1.20209C0.909199 1.49499 0.909199 1.96986 1.20209 2.26275L3.93979 5.00045L1.20209 7.73815C0.909199 8.03104 0.909199 8.50592 1.20209 8.79881C1.49498 9.0917 1.96986 9.0917 2.26275 8.79881L5.00045 6.06111L7.73815 8.79881C8.03104 9.0917 8.50592 9.0917 8.79881 8.79881C9.0917 8.50592 9.0917 8.03104 8.79881 7.73815L6.06111 5.00045L8.79881 2.26275Z" fill="#757A8A"/>
            </svg>
          </span>
        </li>
      </template>
      </ul>
      <ul class="flex justify-center items-center gap-x-1">
        <li v-if="link.tags && link.tags.length > 3" class="custom_tooltip">
          <span class="bg-[#F2F3F8] border border-[#ECEEF5] justify-between items-center bg-[#F2F3F8] text-sm font-poppins px-[0.25rem] rounded-lg max-width-100">+{{ link.tags.length - 3 }} more</span>

          <div class="tool_tip_box">
            <div class="inner_box flex flex-wrap justify-center items-center gap-1">
              <template  v-for="(tag,tag_index) in link.tags"
                        v-if="getTagName(tag) != '' && getTagName(tag) && getTagName(tag).length > 0">

                  <span style="width: fit-content;" v-if="tag_index >= 3" class="flex gap-x-1 bg-[#F2F3F8] border border-[#ECEEF5] justify-between items-center bg-[#F2F3F8] text-sm font-poppins px-[0.25rem] rounded-lg max-width-100">
                  <span class="text-truncate-tag">{{ getTagName(tag) }}</span>
                  <span @click.prevent="removeLinkTag(link._id,index,tag,false,tag_index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79881 2.26275C9.0917 1.96986 9.0917 1.49499 8.79881 1.20209C8.50592 0.909199 8.03104 0.909199 7.73815 1.20209L5.00045 3.93979L2.26275 1.20209C1.96986 0.909199 1.49498 0.909199 1.20209 1.20209C0.909199 1.49499 0.909199 1.96986 1.20209 2.26275L3.93979 5.00045L1.20209 7.73815C0.909199 8.03104 0.909199 8.50592 1.20209 8.79881C1.49498 9.0917 1.96986 9.0917 2.26275 8.79881L5.00045 6.06111L7.73815 8.79881C8.03104 9.0917 8.50592 9.0917 8.79881 8.79881C9.0917 8.50592 9.0917 8.03104 8.79881 7.73815L6.06111 5.00045L8.79881 2.26275Z" fill="#757A8A"/>
            </svg>
                  </span>
                </span>


              </template>

            </div>
          </div>
        </li>
        <li v-tooltip.top-center="'Add Tag'" class="bg-[#F2F3F8] border border-[#ECEEF5] justify-between items-center bg-[#F2F3F8] text-sm font-poppins px-[0.5rem] rounded-lg max-width-100"
            @click.prevent="setAddTagStatus(index,true)">
          <span class="w-3.5 h-3.5 text-[#0165E1] ">+</span>
          <!--<span class="text">add</span>-->
        </li>
      </ul>
    </div>

    <div class="inline_editor" v-else>
      <clip-loader v-if="link.tagLoader" class="icon" style="right: 30px;"
                   :size="size_small" :color="color"></clip-loader>

      <div class="relative tags_dd" v-else v-on-clickaway="blurTag">
        <input maxlength="50"
               v-on:keyup="addLinkListingTag($event,link._id,link.tagValue.trim(),index)"
               @focus="addLinkListingTag($event,link._id,link.tagValue.trim(),index)"
               @blur="blurFocusTag(index)"
               @click="setFocus()"
               type="text" placeholder="Enter tag name..."
               v-model="link.tagValue"
               class="bg-[#ECEEF5] !border-[#ECEEF5]"
        >

        <span class="icon red !bg-transparent" @click.prevent="setAddTagStatus(index,false)">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
            <path d="M6.5 1.5L1.5 6.5" stroke="#3C4549" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.5 1.5L6.5 6.5" stroke="#3C4549" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span class="icon green !bg-transparent" v-if="link.tagValue.length > 0" @click.prevent="addLinkListingTag($event,link._id,link.tagValue.trim(),index,true)">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-[0.625rem] h-[0.5rem]" viewBox="0 0 10 8" fill="none">
            <path d="M8.75 1.1875L3.59375 6.8125L1.25 4.25568" stroke="#3ABA6D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>

        <div class="tag_list_dd w-auto"
             v-if="link.tagValue.length > 0 || focus === true">
          <ul class=""
              v-if="link.tagValue.length > 0 && checkTagNotExist(link.tagValue,link.tags) && focus === true">
            <template v-if="getLinks.suggestedTags.length > 0">
              <li class="old_item"
                  v-for="tag in getLinks.suggestedTags">
                                            <span class="text"
                                                  @click.prevent="addLinkListingTag($event,link._id,getTagName(tag._id),index,true)">
                                                {{ getTagName(tag._id) }}
                                            </span>
              </li>
            </template>
            <li v-else class="new_item">
                                        <span class="text"
                                              @click.prevent="addLinkListingTag($event,link._id,link.tagValue.trim(),index,true)">
                                            Add new tag "{{ link.tagValue }}"
                                        </span>
            </li>

          </ul>
          <ul class=""
              v-else-if="link.tagValue.length === 0 && focus === true">
            <li class="old_item"
                v-for="tag in getLinks.suggestedTags">
                                            <span class="text"
                                                  @click.prevent="addLinkListingTag($event,link._id,getTagName(tag._id),index,true)">
                                                {{ getTagName(tag._id) }}
                                            </span>
            </li>
          </ul>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { linkTypes } from '@/state/modules/mutation-types'

export default {
  data () {
    return {
      focus: true,
    }
  }
  ,
  props: ['link', 'index'],
  computed: {
    ...mapGetters([
      'getLinks'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions([]),
    blurTag () {
      this.focus = false
    },
    setFocus () {
      this.focus = true
    },
    setAddTagStatus (index, status) {
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
      this.$set(this.getLinks.items[index], 'tagValue', '')
      this.$set(this.getLinks.items[index], 'addTag', status)
    },
  }
}
</script>

<style scoped lang="less">
.max-width-100 {
  max-width: 150px;
}
.text-truncate-tag {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag_list_dd{
  overflow-y: auto !important;
}
</style>
